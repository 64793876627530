import React from 'react'
import {graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {Box,Flex,Heading,Text} from '@chakra-ui/react'
import MySEO from '../components/seo'

const Studiofoto = ({data}) => {

return(


    <div>
      <MySEO
        title="Studio- und Produktfotografien"
        description="Beispielarbeiten Studio - und Produktfotografie"
      />
    <Heading># freie projekte</Heading>
    <Text variant="bildtext"><b>Studio-, Landschafts- und Produktfotografie.</b>  hasen oder bein rückt gelegentlich den Dingen auf den Leib oder versucht das bezaubernde Lächeln eines kleinen Mädchens einzufangen. 

    </Text>
    <Flex>

           <Box width={["100%","100%","49%"]}>
                <GatsbyImage alt="" image= {data.bilder.edges[0].node.childImageSharp.gatsbyImageData} />

           </Box>

       <Box width={["100%","100%","49%"]}>
            <GatsbyImage alt="" image= {data.bilder.edges[1].node.childImageSharp.gatsbyImageData} />
       </Box>

     </Flex>
    </div>
)}


export const StudioQuery = graphql`
 query {
  bilder:allFile(filter: {absolutePath: {regex: "/_studiofoto/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
 }


`

  export default Studiofoto
